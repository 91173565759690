<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-29 09:47:38
 * @LastEditTime: 2023-07-06 11:37
 * @Descripttion: 【教学】课件资源
-->
<style lang="scss" scoped>
.courseware {
    @include innerPage;
    @include pageHead(-10px);
    @include pageFoot;

    .page-head {
        height: 224px;
        flex-wrap: wrap;
        padding-top: 0;

        .head-wrapper.column {
            margin-top: -18px;
        }
    }

    &-container {
        width: 100%;
        height: calc(100% - 224px);
        box-sizing: border-box;
        padding-top: 30px;

        .school-search {
            height: 46px;
            box-sizing: border-box;
            padding: 0 24px;
            background: #f1f2f8;
            border-radius: 10px;
            color: #a9a9a9;
            cursor: pointer;
            @include flexBox;

            .iconfont {
                color: #555;
            }

            .search-result {
                color: #272727;
                font-size: 16px;
                @include flexBox;
            }

            .search-result .iconfont {
                font-size: 18px;
                margin-right: 4px;
                padding-left: 12px;
            }

            .ml {
                margin-left: 30px;
            }

            &-wrapper {
                margin-bottom: 32px;
                margin-left: 12px;
                @include flexBox;

                .el-button {
                    margin-left: 22px;
                }
            }
        }
    }

    &-data {
        width: calc(100% + 32px);
        height: 100%;
        margin-left: -32px;
        box-sizing: border-box;
        padding-bottom: 20px;

        &.school {
            height: calc(100% - 152px);
        }

        &.public {
            width: calc(100% + 24px);
            margin-left: -24px;
        }

        &-wrapper {
            width: 100%;
            height: calc(100% - 74px);
            position: relative;
        }

    }

    &-list {
        width: 100%;
        height: 100%;
        padding: 0 40px;
        overflow: hidden;
        overflow-y: auto;
        flex-wrap: wrap;
        box-sizing: border-box;
        @include flexBox(flex-start, flex-start);

        &--public {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            flex-wrap: wrap;
            overflow: hidden;
            overflow-y: auto;
            @include flexBox(flex-start, flex-start);

            .courseware {
                &-item {
                    width: calc((100% - 96px) / 4);
                    height: 364px;
                    margin-left: 24px;
                    border-radius: 10px;
                    overflow: hidden;
                    background: #fff;
                    cursor: default;

                    .el-image{
                        width: 100%;
                        height: 218px !important;
                    }
                    ::v-deep .el-upload {
                        width: 100%;
                        height: 218px !important;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .from {
                        width: 338px;
                        height: 86px;
                        margin: 0 auto;
                        flex-direction: column;
                        line-height: 24px;
                        border-bottom: 1px solid #ececec;
                        @include flexBox(center, flex-start);

                        h5 {
                            color: #3c3b3b;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 100%;
                        }

                        p {
                            color: #787878;
                            margin-top: 6px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 100%;
                        }
                        .boldinput{
                            font-size: 16px;
                            height: 30px;
                            margin-bottom: 5px;
                            width: 100%;
                            font-weight: bold;
                            ::v-deep input{
                                padding: 0 6px;
                                font-size: 16px;
                                height: 30px;
                                border-radius: 5px;
                            }
                        }
                        .linkinput{
                            font-size: 14px;
                            height: 30px;
                            width: 100%;
                            border-radius: 5px;
                            ::v-deep input{
                                padding: 0 6px;
                                font-size: 14px;
                                height: 30px;
                                border-radius: 5px;
                            }
                        }

                    }

                    .target {
                        width: 338px;
                        height: 58px;
                        margin: 0 auto;
                        box-sizing: border-box;
                        padding: 15px;
                        @include flexBox(space-between);

                        .person {
                            color: #7a7a7a;
                        }

                        .pf_bold {
                            font-size: 22px;
                            color: #4d4d4d;
                            margin-right: 4px;
                        }

                        .link {
                            color: #6340c8;

                            &:hover {
                                transform: scale(1.1);
                                cursor: pointer;
                            }
                        }
                        .tfl_sort{
                            width: 80px;
                            height: 32px;
                            ::v-deep input{
                                font-size: 14px;
                                height: 32px;
                                padding: 0 10px;
                                text-align: center;
                            }
                        }
                    }

                    &:hover {
                        box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.15), 5px 5px 27px -10px rgba(108, 78, 203, 0.15);
                    }
                    
                    .avatar-uploader {
                        width: 145px;
                        height: 145px;
                        border-radius: 50%;
                        border: 2px solid #e8eaf3;
                        position: relative;

                        .camera {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        border: 2px solid #ededed;
                        background: #fff;
                        position: absolute;
                        bottom: 0;
                        right: 8px;
                        text-align: center;
                        line-height: 32px;
                        color: #cbcbcb;
                        font-size: 18px;
                        }

                        .avatar,
                        .el-upload {
                            width: 100%;
                            height: 100%;
                        }

                        .avatar {
                        object-fit: cover;
                        border-radius: 50%;
                        }
                    }
                    &.update{
                        ::v-deep .upimg{
                            position: relative;
                            background-color: #afafaf;
                            width: 100%;
                            height: 218px !important;
                            .el-image{
                                opacity: 0.5;
                            }
                            .el-button{
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-item {
        width: calc((100% - 96px) / 3);
        height: 246px;
        margin-bottom: 34px;
        margin-left: 32px;
        position: relative;
        cursor: pointer;

        .info-data {
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;
            flex-direction: column;
            @include flexBox(flex-start);

            h4 {
                width: 100%;
                height: 76px;
                background: #f6f7fc;
                box-sizing: border-box;
                padding: 0 40px;
                color: #474747;
                flex-shrink: 0;
                position: relative;
                @include flexBox;

                .iconfont-color {
                    font-size: 34px;
                    margin-right: 10px;
                }

                &::after {
                    content: '';
                    width: 62px;
                    height: 30px;
                    line-height: 30px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    border-radius: 0 0 0 20px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 14px;
                    color: #fff;
                    transform: scaleY(0);
                    font-family: 'MicrosoftYaHei';
                }
            }

            .desc {
                width: 400px;
                margin: 22px 0 30px;
                line-height: 26px;
                color: #4a4a4a;
                @include ellipsisMultiline;
            }

            .info-bt {
                width: 400px;
                height: 68px;
                border-top: 1px solid #e9e9e9;
                margin-top: auto;
                line-height: 24px;
                flex-shrink: 0;
                @include flexBox(space-between);

                .name {
                    color: #353535;
                }

                .time {
                    color: #949495;
                }

                .collect {
                    color: #4a4a4a;
                    padding: 0;
                    height: 38px;

                    .button-container {
                        @include flexBox;
                    }

                    .iconfont {
                        font-size: 28px;
                        color: #d6d8e1;
                    }

                    &.checked {
                        .iconfont {
                            color: #feaf2f;
                        }
                    }
                }

                .price {
                    margin-left: auto;
                    margin-right: 12px;
                    color: #f66478;
                    padding-top: 5px;

                    .pf_bold {
                        font-size: 24px;
                        margin-left: 4px;
                    }
                }
            }
        }

        .medium-mask {
            width: 100%;
            height: 316px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transform: scaleY(0);
            border-radius: 10px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::before {
                content: '';
                width: 100%;
                height: 100%;
                background: rgba($color: #000, $alpha: .44);
                position: absolute;
                top: 0;
                left: 0;
            }

            .el-button {
                position: absolute;
                bottom: 26px;
                right: 30px;
            }

            .play-icon {
                font-size: 50px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
            }
        }

        &:hover {
            z-index: 1;

            .info-data {
                box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.15), 5px 5px 27px -10px rgba(108, 78, 203, 0.15);
            }
        }

        &.reviewed .info-data,
        &.passed .info-data,
        &.failed .info-data {
            h4:after {
                transform: scaleY(1);
            }
        }

        &.reviewed .info-data h4:after {
            content: '待审核';
            background: #feaf2f;
        }

        &.passed .info-data h4:after {
            content: '已共享';
            background: #2ac293;
        }

        &.failed .info-data h4:after {
            content: '未通过';
            background: #ee4f65;
        }

    }

    .no-data {
        background: #fff;
        border-radius: 10px;
    }
    .favorite{
        height: calc(100% - 140px);
    }
}

.dialog-search {
    box-sizing: border-box;
    padding: 38px 0;

    .grade {
        width: 726px;
        margin: 0 auto 44px;
        @include flexBox(space-between);

        .el-select {
            width: 360px;
        }
    }

    .curriculum {
        height: 50vh;
        max-height: 530px;
        @include flexBox(space-between);

        .edition,
        .edition-no,
        .chapter,
        .sections {
            width: calc((100% - 6px) / 4);
            height: 100%;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 10px 6px 14px 30px;

            h5 {
                height: 46px;
                box-sizing: border-box;
                padding-right: 18px;
                margin-bottom: 16px;
                @include flexBox(space-between);

                span {
                    flex-shrink: 0;
                    margin-right: 10px;
                }
            }

            ::v-deep {
                .el-input {
                    flex-grow: 1;

                    &__inner {
                        background: #fff;
                        padding-right: 60px;
                    }

                    &__suffix {
                        .iconfont {
                            color: #6c4ecb;
                        }

                        .el-input__clear {
                            margin-right: -50px;
                            position: relative;

                            &::after {
                                content: '';
                                width: 1px;
                                height: 10px;
                                left: -2px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                background: #b6b6b6;
                            }
                        }
                    }
                }
            }
        }

        .edition {
            background: rgba($color: #d83131, $alpha: 0.05);
        }

        .edition-no {
            background: rgba($color: #2ac293, $alpha: 0.05);
        }

        .chapter {
            background: rgba($color: #feaf2f, $alpha: 0.05);
        }

        .sections {
            background: rgba($color: #6c4ecb, $alpha: 0.05);
        }

        &-item {
            height: calc(100% - 62px);
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: auto;

            li {
                line-height: 42px;
                margin-bottom: 20px;
                font-size: 16px;
                color: #383838;

                &:last-child {
                    margin-bottom: 0;
                }

                &.current,
                &:not(.current):not(.not-data):hover {
                    color: #6c4ecb;
                    cursor: pointer;
                }

                &.not-data {
                    height: 100%;
                    flex-direction: column;
                    @include flexBox(center);

                    img {
                        width: 125px;
                    }

                    p {
                        color: #b6b6b6;
                        margin-top: 6px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.dialog-upload {
    box-sizing: border-box;
    padding-top: 40px;

    .el-form {
        box-sizing: border-box;
        padding: 0 20px 0 34px;

        ::v-deep {
            .el-upload {
                &--text {
                    width: 150px;
                    height: 100px;

                    .el-upload-dragger .iconfont {
                        margin-top: 24px;
                        margin-bottom: 0;
                    }
                }

                .tips {
                    margin-top: -14px;
                }
            }
        }
    }

    .file-list {
        .file-item {
            height: 60px;
            @include flexBox(space-between, flex-end);

            .iconfont-color {
                font-size: 52px;
                flex-shrink: 0;
                line-height: 60px;
                flex-shrink: 0;
            }

            .el-button {
                flex-shrink: 0;
                margin-bottom: 6px;
            }

            .name {
                color: #212122;
                flex-grow: 1;
                max-width: 328px;
                margin: 0 20px 10px;
            }
        }
    }
}
</style>
<style lang="scss">
.large-dialog{
  max-width: 1280px;
  min-width: 1000px;
  width: 80%;
}
</style>

<template>
    <section class="courseware">
        <div class="page-head">
            <div class="page-head--inner">
                <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
                <div class="head-wrapper column">
                    <breadcrumb />
                    <div class="wrap-plate">
                        <div class="single-search">
                            <el-input v-model.trim="searchKey" maxlength="100" placeholder="搜索资源名称" clearable
                                @clear="handleSearch">
                                <span slot="prefix" class="iconfont">&#xe61c;</span>
                            </el-input>
                            <el-button type="custom_primary" size="medium" @click="handleSearch">搜索</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-tab">
                <ul class="tabs bold">
                    <li class="tab" :class="{ current: currentTab == item.value }" v-for="item in tabs" :key="item.value"
                        @click="changeTab(item.value)">
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
        <!-- 课件内容 -->
        <div class="courseware-container">
            <!-- 课件筛选 -->
            <div class="school-search-wrapper"
                v-if="currentTab == 'my' || currentTab == 'collect' || currentTab == 'school'">
                <div class="school-search">
                    <p class="search-result" v-html="searchTitle">
                    </p>
                    <p @click="showDialog" :class="{ ml: searchTitle }">
                        <span>筛选</span>
                        <span class="iconfont">&#xe600;</span>
                    </p>
                </div>
                <el-button type="custom_primary" size="medium" v-if="currentTab == 'my'" @click="uploadDialog = true">
                    上传资源
                </el-button>
            </div>
            <!-- 收藏新增 -->
            <div class="school-search-wrapper" v-if="currentTab == 'favorite'">
                <el-button type="custom_primary" size="medium" @click="addFavorite">
                    新增收藏
                </el-button>
            </div>
            <!-- 列表 -->
            <div class="courseware-data-wrapper" :class="{ 'no-data': (!tableData.length&&!add_favorite), 'favorite': currentTab == 'favorite' }">
                <div class="courseware-data" :class="{ school: currentTab == 'school', public: currentTab == 'public' }" v-show="tableData.length||add_favorite">
                    <!-- 我的 -->
                    <ul class="courseware-list" v-if="currentTab == 'my'">
                        <li class="courseware-item" v-for="item in tableData" :key="item.tecou_id"
                            :class="{ reviewed: item.tecou_sharestatus == 20, passed: item.tecou_sharestatus == 30, failed: item.tecou_sharestatus == 40 }"
                            @click="targetDetail(item.tecou_source, item.tecou_id)">
                            <div class="info-data">
                                <h4 class="bold">
                                    <span class="iconfont-color" v-html="fileType(item.tecou_type)"></span>
                                    <p class="line-text--1st">{{ item.tecou_title }}</p>
                                </h4>
                                <p class="desc light">{{ item.tecou_remark }}</p>
                                <div class="info-bt">
                                    <p>
                                        <span class="name">{{ item.teuse_name }}</span>
                                        <br>
                                        <span class="time pf">{{ item.create_time | formatTime('YYYY/MM/DD')
                                        }}</span>
                                    </p>
                                    <el-button type="custom_primary" size="small"
                                        v-if="item.tecou_sharestatus != 20 && item.tecou_sharestatus != 30 && userInfo.enter == 1"
                                        @click.stop="shareFile(item.tecou_id)">
                                        {{ item.tecou_sharestatus == 40 && '重新' || '' }}共享
                                    </el-button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 收藏、校本 -->
                    <ul class="courseware-list" v-if="currentTab == 'collect' || currentTab == 'school'">
                        <li class="courseware-item" v-for="(item, index) in tableData" :key="index"
                            @click="targetDetail(item.tecou_source || item.sccou_source, item.tecou_id || item.sccou_id)">
                            <div class="info-data">
                                <h4 class="bold">
                                    <span class="iconfont-color"
                                        v-html="fileType(item.tecou_type || item.sccou_type)"></span>
                                    <p class="line-text--1st">{{ item.sccou_title || item.tecou_title }}</p>
                                </h4>
                                <p class="desc light">
                                    {{ item.tecou_remark || item.sccou_remark }}
                                </p>
                                <div class="info-bt">
                                    <p>
                                        <span class="name">{{ item.teuse_name }}</span>
                                        <br>
                                        <span class="time pf">
                                            {{ item.create_time | formatTime('YYYY/MM/DD') }}
                                        </span>
                                    </p>
                                    <el-button type="text" class="light collect" :class="{ checked: item.is_collect == 1 }"
                                        @click.stop="collectFile(item.is_collect, item.tecou_id || item.sccou_id, item.tecou_source || 20)">
                                        <p class="button-container">
                                            <span class="iconfont">&#xe630;</span>
                                            <span>
                                                {{ item.is_collect == 1 && '已收藏' || '收藏' }}
                                            </span>
                                        </p>
                                    </el-button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 平台 -->
                    <ul class="courseware-list" v-if="currentTab == 'platform'">
                        <li class="courseware-item" v-for="item in tableData" :key="item.plcou_id"
                            @click="targetDetail(item.plcou_source, item.plcou_id)">
                            <div class="info-data">
                                <h4 class="bold">
                                    <span class="iconfont-color" v-html="fileType(item.plcou_type)"></span>
                                    <p class="line-text--1st">{{ item.plcou_title }}</p>
                                </h4>
                                <p class="desc light">
                                    {{ item.plcou_remark }}
                                </p>
                                <div class="info-bt">
                                    <el-button type="text" class="light collect" :class="{ checked: item.is_collect == 1 }"
                                        @click.stop="collectFile(item.is_collect, item.plcou_id, 30)">
                                        <p class="button-container">
                                            <span class="iconfont">&#xe630;</span>
                                            <span>{{ item.is_collect == 1 && '已收藏' || '收藏' }}</span>
                                        </p>
                                    </el-button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 公共 -->
                    <ul class="courseware-list--public" v-if="currentTab == 'public'">
                        <li class="courseware-item" v-for="item in tableData" :key="item.plcou_pub_id">
                            <el-image fit="scale-down" :src="formatfile(item.plcou_pub_image)" />
                            <div class="from">
                                <h5 class="bold">{{ item.plcou_pub_title }}</h5>
                                <p class="link">{{ item.plcou_pub_url }}</p>
                            </div>
                            <div class="target">
                                <p class="person"><span class="pf_bold">{{ item.plcou_pub_num }}</span><span>人已使用</span>
                                </p>
                                <span class="link" @click="targetPublicCourseware(item.plcou_pub_url, item.plcou_pub_id)">
                                    立即访问&gt;
                                </span>
                            </div>
                        </li>
                    </ul>
                    <!-- 收藏夹 -->
                    <ul class="courseware-list--public" v-if="currentTab == 'favorite'">
                        <!-- 新增 -->
                        <li v-if="add_favorite" class="courseware-item update" v-loading="compileLoading">
                            <el-upload :action="$upload.imgAction" :headers="$upload.header" :show-file-list="false"
                                :accept="$upload.imgAccept" :before-upload="$beforImgUpload" :on-success="handleAvatarSuccess">
                                <div class="upimg">
                                    <el-image fit="scale-down" :src="formatfile(compile.tfl_image)" />
                                    <el-button type="primary">上传 <i class="el-icon-upload el-icon--right"></i></el-button>
                                </div>
                            </el-upload>
                            <div class="from">
                                <el-input class="boldinput" v-model.trim="compile.tfl_title" maxlength="50" placeholder="请输入名称"/>
                                <el-input class="linkinput" v-model.trim="compile.tfl_url" maxlength="200" placeholder="请输入链接地址"/>
                            </div>
                            <div class="target">
                                <p>
                                    <el-button type="info" size="mini" plain @click="add_favorite=false">取消</el-button>
                                    <el-button type="primary" size="mini" @click="subCompile()">保存</el-button>
                                </p>
                                <el-input class="tfl_sort" v-model.trim="compile.tfl_sort" maxlength="4" placeholder="排序号" oninput="value=value.replace(/[^0-9.]/g,'')" type="number"/>
                            </div>
                        </li>
                        <!-- 列表 -->
                        <template v-for="(item,index) in tableData">
                            <!-- 编辑 -->
                            <li class="courseware-item update" v-if="item.compile==true" :key="index" v-loading="compileLoading">
                                <el-upload :action="$upload.imgAction" :headers="$upload.header" :show-file-list="false"
                                    :accept="$upload.imgAccept" :before-upload="$beforImgUpload" :on-success="handleAvatarSuccess">
                                    <div class="upimg">
                                        <el-image fit="scale-down" :src="formatfile(compile.tfl_image)" />
                                        <el-button type="primary">上传 <i class="el-icon-upload el-icon--right"></i></el-button>
                                    </div>
                                </el-upload>
                                <div class="from">
                                    <el-input class="boldinput" v-model.trim="compile.tfl_title" maxlength="50" placeholder="请输入名称"/>
                                    <el-input class="linkinput" v-model.trim="compile.tfl_url" maxlength="200" placeholder="请输入链接地址"/>
                                </div>
                                <div class="target">
                                    <p>
                                        <el-button type="info" size="mini" plain @click="setcompile(index,false)">取消</el-button>
                                        <el-button type="primary" size="mini" @click="upCompile()">保存</el-button>
                                    </p>
                                    <el-input class="tfl_sort" v-model.trim="compile.tfl_sort" maxlength="5" placeholder="排序号" oninput="value=value.replace(/[^0-9.]/g,'')" type="number"/>
                                </div>
                            </li>
                            <li class="courseware-item" v-else :key="item.tfl_id">
                                <el-image fit="scale-down" :src="formatfile(item.tfl_image)" />
                                <div class="from">
                                    <h5 class="bold" :title="item.tfl_title">{{ item.tfl_title }}</h5>
                                    <p class="link" :title="item.tfl_url">{{ item.tfl_url }}</p>
                                </div>
                                <div class="target">
                                    <p class="person">
                                        <el-button type="primary" size="mini" @click="setcompile(index,true)" icon="el-icon-edit"></el-button>
                                        <el-button type="danger" size="mini" @click="delcompile(item.tfl_id)" icon="el-icon-delete"></el-button>
                                    </p>
                                    <a class="link" :href="item.tfl_url" target="_blank">
                                        立即访问&gt;
                                    </a>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="no-data--empty" v-if="!tableData.length&&!add_favorite">
                    <img src="@assets/images/no-data3.png" alt="">
                    <p>暂无资源~</p>
                </div>
            </div>
            <!-- 分页控件 -->
            <div class="custom-foot">
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" :size="tablePage.pageSize" @pageChange="flippingPage" />
            </div>
        </div>
        <!-- 数据筛选弹窗 -->
        <el-dialog title="筛选内容" width="1400px" :visible.sync="searchDialog" custom-class="large-dialog" @close="hideDialog('search')">
            <div class="dialog-search">
                <div class="curriculum">
                    <div class="edition">
                        <h5 class="bold">年级</h5>
                        <ul class="curriculum-item">
                            <li :class="{ current: searchChoose.grade && searchChoose.grade.id == item.grade_id }"
                                v-for="item in gradeList" :key="item.grade_id"
                                @click="chooseSearchKey('grade', item.grade_id, item.grade_name)">
                                {{ item.grade_name }}
                            </li>
                        </ul>
                    </div>
                    <div class="edition-no">
                        <h5 class="bold">科目</h5>
                        <ul class="curriculum-item">
                            <li :class="{ current: searchChoose.sysub && searchChoose.sysub.id == item.sysub_id }"
                                v-for="item in subjectList" :key="item.sysub_id"
                                @click="chooseSearchKey('sysub', item.sysub_id, item.sysub_name)">
                                {{ item.sysub_name }}
                            </li>
                        </ul>
                    </div>
                    <div class="sections">
                        <h5>
                        <span class="bold">教材</span>
                        <el-input
                            v-model.trim="sccouKey"
                            maxlength="100"
                            placeholder="请输入关键字"
                            clearable
                            @change="get_school_course_list()"
                            @clear="get_school_course_list()"
                        >
                            <span slot="suffix" class="iconfont">&#xe61c;</span>
                        </el-input>
                        </h5>
                        <ul class="curriculum-item">
                        <template v-if="sccouData && sccouData.length">
                            <li :class="{ current:searchChoose.sccou && searchChoose.sccou.id == item.sccou_id }"
                            v-for="item in sccouData"
                            :key="item.sysub_id"
                            @click="
                                chooseSearchKey(
                                'sccou',
                                item.sccou_id,
                                item.title
                                )
                            "
                            >
                            {{ item.title }}
                            </li>
                        </template>
                        <li class="not-data" v-else>
                            <img src="@assets/images/no-data2.png" alt />
                            <p>暂无选项哦~</p>
                        </li>
                        </ul>
                    </div>
                    <div class="chapter">
                        <h5>
                            <span class="bold">章</span>
                            <el-input v-model.trim="chapterKey" maxlength="100" placeholder="请输入关键字" clearable
                                @change="getChapters(1)" @clear="getChapters(1)">
                                <span slot="suffix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </h5>
                        <ul class="curriculum-item">
                            <template v-if="chapterData && chapterData.length">
                                <li :class="{ current: searchChoose.chaper && searchChoose.chaper.id == item.sccou_cha_id }"
                                    v-for="item in chapterData" :key="item.sysub_id"
                                    @click="chooseSearchKey('chaper', item.sccou_cha_id, item.sccou_cha_title)">
                                    {{ item.sccou_cha_title }}
                                </li>
                            </template>
                            <li class="not-data" v-else>
                                <img src="@assets/images/no-data2.png" alt="">
                                <p>暂无选项哦~</p>
                            </li>
                        </ul>
                    </div>
                    <div class="sections">
                        <h5>
                            <span class="bold">节</span>
                            <el-input v-model.trim="jointKey" maxlength="100" placeholder="请输入关键字" clearable
                                @change="getChapters(2)" @clear="getChapters(2)">
                                <span slot="suffix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </h5>
                        <ul class="curriculum-item">
                            <template v-if="jointData && jointData.length">
                                <li :class="{ current: searchChoose.joint && searchChoose.joint.id == item.sccou_cha_id }"
                                    v-for="item in jointData" :key="item.sysub_id"
                                    @click="chooseSearchKey('joint', item.sccou_cha_id, item.sccou_cha_title)">
                                    {{ item.sccou_cha_title }}
                                </li>
                            </template>
                            <li class="not-data" v-else>
                                <img src="@assets/images/no-data2.png" alt="">
                                <p>暂无选项哦~</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div slot="footer" class="foot-center">
                <el-button type="custom_primary" size="small" @click="handleSearch">确 定</el-button>
                <el-button type="custom_warning" size="small" @click="resetSearchChoose">重 置</el-button>
            </div>
        </el-dialog>
        <!-- 上传资源 -->
        <el-dialog title="上传资源" :visible.sync="uploadDialog" width="558px" :show-close="false">
            <div class="dialog-upload">
                <el-form ref="uploadForm" :model="uploadForm" label-width="68px" :rules="uploadRules">
                    <el-form-item label="资源标题" prop="tecou_title">
                        <el-input v-model.trim="uploadForm.tecou_title" maxlength="100" placeholder="请输入" />
                    </el-form-item>
                    <el-form-item label="资源描述" prop="tecou_remark">
                        <el-input v-model.trim="uploadForm.tecou_remark" type="textarea" maxlength="200"
                            placeholder="请输入" />
                    </el-form-item>
                    <el-form-item label="年级" prop="tecou_grade">
                        <el-select v-model="uploadForm.tecou_grade" placeholder="请选择" filterable @change="getChaptersTree">
                            <el-option v-for="item in gradeList" :key="item.grade_id" :label="item.grade_name"
                                :value="item.grade_id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="科目" prop="sysub_id">
                        <el-select v-model="uploadForm.sysub_id" placeholder="请选择" filterable @change="getChaptersTree">
                            <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                                :value="item.sysub_id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关联章节" prop="chapter" v-if="uploadForm.tecou_grade && uploadForm.sysub_id">
                        <el-cascader v-model="uploadForm.chapter" :options="chaptersTree" clearable
                            :props="{ value: 'sccou_cha_id', label: 'sccou_cha_title' }" placeholder="请选择" />
                    </el-form-item>
                    <el-form-item label="资源" prop='tecou_url'>
                        <el-upload class="upload-table" :action="$upload.fileAction" :headers="$upload.header" drag 
                            :show-file-list="coursewareList.length==0" 
                            :file-list="coursewareList" 
                            :limit="1"
                             accept=".ppt,.pptx,.pdf,.doc,.docx,.mp4,.avi,.wmv,.mpeg"
                            :before-upload="uploadBefore"
                            :on-success="uploadSuccess" 
                            :on-error="()=>{uploaLoading=false}"
                            :on-remove="()=>{uploaLoading=false}">
                            <p class="iconfont">&#xe603;</p>
                            <p class="tips">拖拽或<span>点击上传</span></p>
                        </el-upload>
                        <ul class="file-list">
                            <li class="file-item" v-for="(item, index) in coursewareList" :key="index">
                                <span class="iconfont-color" v-html="fileType(item.type)" />
                                <p class="name line-text--1st">{{ item.name }}</p>
                                <el-button class="iconfont mini" type="custom_primary" circle
                                    @click="removeCoursewareFile">&#xe620;</el-button>
                            </li>
                        </ul>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="foot-center">
                <el-button type="custom_info" size="small" @click="hideDialog('upload')">取 消</el-button>
                <el-button type="custom_primary" size="small" @click="addCourseware" :disablde="btnload"
                    v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import * as teach from "@api/teaching"
import { $getChapters,$get_school_course_list } from "@api/home"
import { formatFileType, formatFile } from "@utils"
import { mapState } from "vuex"
import customPagination from "@comp/customPagination"
export default {
    name: 'teaching_courseware',
    components: { customPagination },
    computed: {
        ...mapState('common', ['gradeList', 'subjectList']),
        ...mapState('user', ['userInfo']),
        fileType() {
            return function (val) {
                return formatFileType(val);
            }
        }, formatfile() {
            return function (url) {
                return formatFile(url)
            }
        },
    },
    data() {
        /* 文件上传校验规则 */
        const fileMustUpload = (rule, value, callback) => {
            if (!this.uploadForm.tecou_url) {
                // 未上传文件
                callback(this.uploaLoading?"正在上传课件资源":"请上传课件资源");
            }
            callback();
        };
        return {
            tabs: [{
                label: '我的资源',
                value: 'my'
            }, {
                label: '我的收藏',
                value: 'collect'
            }, {
                label: '校本资源',
                value: 'school'
            }, {
                label: '学校共享资源',
                value: 'platform'
            }, {
                label: '公共资源',
                value: 'public'
            }, {
                label: '收藏夹',
                value: 'favorite'
            }], // 标签页分类
            currentTab: 'my', // 当前标签页
            /* 筛选课件 */
            searchDialog: false, // 显示筛选弹窗
            searchTitle: '', // 筛选章节文本
            searchChoose: {}, // 筛选选中数据
            searchResult: {}, // 筛选数据集合
            sccouData: [], // 教材
            sccouKey:"",//教材（关键字）
            chapterData: [], // 章
            chapterKey: '', // 筛选章（关键字）
            jointData: [], // 节
            jointKey: '', // 筛选节（关键字）
            searchKey: '',
            tableData: [], // 课件列表数据
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                pageSize: 12,
                total: 0
            },
            /* 资源上传 */
            uploadDialog: false, // 显示课件资源上传弹窗
            uploaLoading:false,//课件上传中
            uploadForm: {}, // 课件资源上传表单
            chaptersTree: [], // 章节数据（树）
            coursewareList: [], // 课件资源列表
            btnload: false,
            uploadRules: {
                tecou_title: [{ required: true, message: '请填写课件资源名称', trigger: 'blur' }],
                tecou_remark: [{ required: true, message: '请填写课件资源描述', trigger: 'blur' }],
                tecou_url: [{ validator: fileMustUpload, trigger: 'change' }],
                tecou_grade: [{ required: true, message: '请选择课件资源关联年级', trigger: 'change' }],
                sysub_id: [{ required: true, message: '请选择课件资源关联科目', trigger: 'change' }],
                chapter: [{ required: true, message: '请选择课件资源关联章节', trigger: 'change' }],
            },
            //收藏夹正在操作修改的内容
            compile:{},
            // 是否显示添加收藏夹
            add_favorite:false,
            //等待
            compileLoading:false,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取课件列表 */
        async getData() {
            let { grade, sysub, chaper, joint } = this.searchResult;
            let params = {
                query_word: this.searchKey,
                pageindex: this.tablePage.pageIndex,
            }
            if (this.currentTab == 'my' && grade) params.tecou_grade = grade.id;
            if (this.currentTab == 'school' && grade) params.sccou_grade = grade.id;
            if (sysub) params.sysub_id = sysub.id;
            if (chaper) params.sccou_cha_id = chaper.id;
            if (joint) params.sccou_cha_id = joint.id;

            if(this.currentTab=="favorite"){
                let { data: res } = await teach.$teafavoritelinkList(params, this.tablePage.pageSize);
                this.tablePage.total = res.allcount;
                this.tableData = res.data;
            }else{
              
                let { data: res } = await teach.$getCourseware(this.currentTab, params, this.tablePage.pageSize);
                this.tablePage.total = res.allcount;
                if (this.currentTab == 'collect') {
                    res.data.map(item => item.is_collect = 1)
                }  
                this.tableData = res.data;
            }
            this.$forceUpdate();
        },
        /**
         * 资源共享
         * @param {number} id 课件id
         */
        shareFile(id) {
            this.$msgbox({
                title: "提示",
                message: "确认将此课件资源共享至学校？",
                type: "info",
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: "el-button--custom_info",
                confirmButtonClass: "el-button--custom_primary",
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(async () => {
                let res = await teach.$shareCourseware(id);
                if (res) {
                    this.$message({
                        type: "success",
                        duration: 1500,
                        message: "申请已提交，等待学校审核",
                    });
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: "info",
                    duration: 1500,
                    message: "操作已取消",
                });
            });
        },
        /**
         * （取消）收藏课件
         * @param {number} type 操作类型
         * @param {number} id 课件id
         * @param {number} from 课件来源
         */
        async collectFile(type, id, from) {
            let params = {
                tecou_id: id,
                type: type == 1 && 2 || 1,
                tecou_col_origin: from
            }
            let res = await teach.$collectCourseware(params);
            if (res) {
                this.$message.success('操作成功');
                this.getData();
            }
        },
        /**
         * 公共资源访问量增加
         * @param {string} url 公共课件地址
         * @param {numer} id 公共课件id
         */
        async targetPublicCourseware(url, id) {
            let res = await teach.$publicCoursewareAddView(id);
            if (res) return window.open(url, '_blank')
        },
        /**
         * 筛选
         * ---- 获取学校教材
         * @param {int}  grade 年级  sysub_id 科目  chapter_word 章节关键字 is_trees 是否返回树状1是 2否
         */
        async get_school_course_list() {
            let { grade, sysub } = this.searchChoose;
            if (!grade || !sysub) return;
            delete this.searchChoose.sccou; // 清除选中的节id
            let params = {
                chapter_word:this.sccouKey,
                grade: grade.id,
                sysub_id: sysub.id,
            };
            let { data } = await $get_school_course_list(params);
            this.sccouData = data
            this.$forceUpdate();
        },
        /**
         * 获取章节数据
         * @param {int} type 数据类型 1：章   2：节
         */
        async getChapters(type) {
            let { grade, sysub, chaper, sccou } = this.searchChoose
            if (!grade || !sysub || !sccou) return;
            delete this.searchChoose.joint; // 清除选中的节id
            if(this.$isEmpty(chaper) && type == 2) return;
            let params = {
                chapter_word: type == 1 && this.chapterKey || this.jointKey,
                grade: grade.id,
                sysub_id: sysub.id,
                sccou_id:sccou.id
            }
            /* 若选中章触发，传递章id获取节数据 */
            if (chaper && type != 1) params.sccou_cha_pid = chaper.id;
            let { data } = await $getChapters(params);
            /* 章 */
            if (type == 1) {
                this.chapterData = data[0] || [];
                /* 章数据更新，清除选中章及节数据 */
                delete this.searchChoose.chaper;
                this.jointData = [];
                this.jointKey = ''
            }
            /* 节 */
            else {
                this.jointData = data[0] || []
            }
            this.$forceUpdate();
        },
        /** 获取章节数据(树) */
        async getChaptersTree() {
            let { tecou_grade: grade, sysub_id } = this.uploadForm;
            if (grade && sysub_id) {
                let params = { grade, sysub_id, is_trees: 1 }
                let { data } = await $getChapters(params);
                data = data[0] || [];
                data.forEach((item) => {
                    if (this.$isEmpty(item.children)) delete item.children;
                    else {
                        item.children.forEach(citem => {
                            if (this.$isEmpty(citem.children)) delete citem.children;
                        })
                    }
                });
                this.chaptersTree = data;
                this.$forceUpdate();
            }
        },
        /**
         * 筛选节点选中
         * @param {string} el 筛选数据key
         * @param {number} val 筛选数据value
         */
        chooseSearchKey(el, id, name) {
            this.searchChoose[el] = { id, name };
            /* 根据选中数据类型，清空对应子级数据 */
            switch (el) {
                case 'grade':
                    delete this.searchChoose.sysub//科目
                    delete this.searchChoose.sccou;//教材
                    delete this.searchChoose.chaper;//章
                    delete this.searchChoose.joint;//节
                    this.chapterData = [];
                    this.sccouData = [];
                    this.jointData = [];
                    this.sccouKey = "";
                    this.chapterKey = "";
                    this.jointKey = "";
                    break;
                case 'sysub':
                    delete this.searchChoose.sccou;
                    delete this.searchChoose.chaper;
                    delete this.searchChoose.joint;
                    this.sccouData = [];
                    this.chapterData = [];
                    this.jointData = [];
                    this.sccouKey = "";
                    this.chapterKey = '';
                    this.jointKey = ''
                    break;
                case "sccou"://选中教材清理数据
                    delete this.searchChoose.chaper;
                    delete this.searchChoose.joint;
                    this.chapterData = [];
                    this.jointData = [];
                    this.chapterKey = "";
                    this.jointKey = "";
                    break;
                case 'chaper':
                    delete this.searchChoose.joint;
                    this.jointData = [];
                    this.jointKey = ''
                    break;
                default:
                    break;
            }
            /* 选中科目时获取教材的时候请求*/
            if (this.searchChoose.grade && this.searchChoose.sysub && el == "sysub") {
                // 请求教材列表
                this.get_school_course_list();
            }
            /* 选中科目/章时，获取章节数据 */
            if (this.searchChoose.grade && this.searchChoose.sysub && this.searchChoose.sccou && el != 'joint') {
                let type = el == 'chaper' && 2 || 1
                this.getChapters(type);
            }
            this.$forceUpdate();
        },
        /** 筛选数据 */
        handleSearch() {
            this.searchResult = { ...this.searchChoose };
            let { grade, sysub, chaper, joint } = this.searchChoose;
            this.searchChoose = {};
            this.searchTitle = `${grade && `<span class="iconfont">&#xe607;</span>${grade.name}` || ''}${sysub && sysub.name || ''}${chaper && `：${chaper.name}` || ''}${joint && `&nbsp;-&nbsp;${joint.name}` || ''}`
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.hideDialog('search');
            this.getData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        /** 切换标签页 */
        changeTab(val) {
            if (this.currentTab == val) return;
            this.currentTab = val;
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.searchResult = {};
            this.searchChoose = {};
            this.searchTitle = this.searchKey = '';
            this.add_favorite=false
            this.getData();
        },
        // 上传课件
        uploadBefore(file){
        if(this.uploaLoading){
            this.$message.warning("文件上传中，请稍后");
            return false
        }else if(this.coursewareList.length>0){
            this.$message.warning("超出上传个数，请删除后再上传！");
            return false
        }
        this.uploaLoading=true;
        this.$beforFileUpload(file, ['ppt','PPT', 'pptx','PPTX', 'pdf', 'PDF', 'doc', 'DOC', 'docx','DOCX', 'mp4','MP4', 'avi','AVI', 'wmv', 'WMV', 'mpeg', 'MPEG']);
        },
        /**
         * 文件上传成功
         * @param {object} data 响应结果
         * @param {string} name 上传原文件名
         */
        uploadSuccess({ data, msg }, { name }) {
            if (this.$isEmpty(data)) return this.$message.error(msg);
            this.uploadForm.tecou_url = data.address;
            this.uploadForm.tecou_type = data.file_type;
            let courseware = { name, type: data.file_type };
            this.coursewareList = [courseware];
            this.$refs.uploadForm.validateField('tecou_url');
            this.uploaLoading=false;
        },
        // 收藏夹上传图片
        handleAvatarSuccess({ data, msg }) {
            if (this.$isEmpty(data)) return this.$message.error(msg);
            this.compile.tfl_image = data.address;
            this.$forceUpdate()
        },
        //收藏夹切换为修改数据
        setcompile(index,type){
            //取消正在编辑的收藏
            this.tableData.forEach(item=>{
                item.compile=false
            })
            this.add_favorite=false
            setTimeout(() => {
                this.compileLoading=true
                this.compile=JSON.parse(JSON.stringify(this.tableData[index]))
                this.tableData[index].compile=type
                this.$forceUpdate()
                this.compileLoading=false
            }, 300);
        },
        async delcompile(tfl_id){
            this.$msgbox({
                title: '清除',
                message: '删除此收藏？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                let res = await teach.$teafavoritelinkdelete(tfl_id);
                if (res) {
                    this.tablePage.pageIndex = 1;
                    this.getData();
                }

            })
        },
        //修改收藏夹
        async upCompile(){
            if(this.compile.tfl_title==""){
                this.$message({
                    message: '请输入名称',
                    type: 'warning'
                });
                return false;
            }else if(this.compile.tfl_url==""){
                this.$message({
                    message: '请输入地址',
                    type: 'warning'
                });
                return false;
            }
            this.compileLoading=true
            this.compile.tfl_url=this.getossimg(this.compile.tfl_url)
            let res =  await teach.$teafavoritelinkedit(this.compile);
            if(res.status==200){
                this.tablePage.pageIndex = 1;
                this.getData();
            }  
            this.compileLoading=false
        },
        //保存收藏夹
        async subCompile(){
            if(this.compile.tfl_title==""){
                this.$message({
                    message: '请输入标题',
                    type: 'warning'
                });
                return false;
            }else if(this.compile.tfl_url==""){
                this.$message({
                    message: '请输入地址',
                    type: 'warning'
                });
                return false;
            }
            this.compileLoading=true
            this.compile.tfl_url=this.getossimg(this.compile.tfl_url)
            let res =  await teach.$teafavoritelinkadd(this.compile);
            if(res.status==200){
                this.tablePage.pageIndex = 1;
                this.add_favorite=false
                this.getData();
            }  
            this.compileLoading=false
        },
        //添加收藏夹
        addFavorite(){
            this.add_favorite=true
            this.compile={
                tfl_title:"",
                tfl_url:"",
                tfl_image:"",
                tfl_sort:"",
            }
            //取消正在编辑的收藏
            this.tableData.map(item=>{
                item.compile=false
            })
        },
        getossimg(url) {
            if (url.includes("http")) {
                return url
            } else {
                return "http://" + url
            }
        },
        /** 
         * 移出上传课件资源
         */
        removeCoursewareFile() {
            delete this.uploadForm.tecou_url;
            this.coursewareList = [];
        },
        /** 上传新增课件资源 */
        addCourseware() {
            this.$refs.uploadForm.validate(async (valid) => {
                if (valid) {
                    this.btnload = true;
                    let params = {
                        ...this.uploadForm,
                        sccou_cha_id: this.uploadForm.chapter[this.uploadForm.chapter.length - 1],
                    }
                    delete params.chapter;
                    let res = await teach.$addCourseware(params);
                    this.btnload = false;
                    if (res) {
                        this.hideDialog("upload");
                        this.getData();
                        this.$message.success('资源上传成功')
                    }
                }
            });
        },
        /** 重置选中筛选节点 */
        resetSearchChoose() {
            this.searchChoose = {};
            this.chapterData = [];
            this.chapterKey = '';
            this.jointData = [];
            this.jointKey = '';
        },
        /** 打开弹窗 */
        showDialog() {
            this.searchDialog = true;
            let { grade, sysub, chaper, joint } = this.searchResult;
            if (grade) {
                this.chooseSearchKey('grade', grade.id, grade.name);
            }
            if (sysub) {
                this.chooseSearchKey('sysub', sysub.id, sysub.name);
            }
            if (chaper) {
                this.chooseSearchKey('chaper', chaper.id, chaper.name);
            }
            if (joint) {
                this.chooseSearchKey('joint', joint.id, joint.name);
            }
        },
        /**
         * 关闭弹窗
         * @param {string} type 弹窗类型
         */
        hideDialog(type) {
            this[`${type}Dialog`] = false;
            if (this[`${type}Form`]) {
                if (type == 'upload') {
                    this.coursewareList = []; 
                    this.chaptersTree = [];
                    this.uploaLoading=false;
                }
                this.resetForm(`${type}Form`);
                this[`${type}Form`] = {};
            }
            if (type == 'search') {
                this.resetSearchChoose();
            }
            if (this[type]) {
                this[type] = {}
            }
        },
        /** 重置表单 */
        resetForm(ref) {
            this.$refs[ref].resetFields();
        },
        /** 删除上传文件 */
        deleteUploadFile(index) {
            this.fileList.splice(index, 1)
        },
        /**
         * 跳转详情
         * @param {number} type 课件资源来源
         */
        targetDetail(type, id) {
            if (this.currentTab == 'collect') type = type == 30 && 'platform' || type == 20 && 'school' || '';
            else type = this.currentTab;
            this.$router.push({
                name: 'TEACHING_COURSEWARE_DETAIL',
                params: { id, type }
            })
        },
    }
}
</script>